<template>
  <div class="promo">
    <div class="container">
      <div class="promo__header">
        <div class="promo__img">
          <img src="@/assets/img/icons/logo.svg" alt="" />
          <span>Промо</span>
        </div>
        <div class="promo__slogan">Только для вас!</div>
      </div>
      <div class="promo__content">
        <p class="promo__content-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco
        </p>
        <p class="promo__content-discount">Скидки действуют до 30.09.2022</p>
      </div>
      <div class="promo__inner">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="product-block"
        >
          <div class="product-pdf__header">
            <div class="product-pdf__price">
              <div v-if="true" class="product-pdf__stock">
                {{ product.discount }}
                <img
                  v-if="false"
                  class="product-pdf__header-icon"
                  src="@/assets/img/icons/best_price-icon-black.svg"
                  alt=""
                />
              </div>
              <div v-else class="product-pdf__stock">
                <img
                  class="product-pdf__header-icon"
                  src="@/assets/img/icons/best_price-icon-white.svg"
                  alt=""
                />
              </div>
              <div
                class="product-pdf__price-wrapper"
                :class="{
                  'best-price': false,
                }"
              >
                <span class="product-pdf__price-old">
                  {{ product.oldPrice }}
                </span>
                <span class="product-pdf__price-new">
                  {{ product.newPrice }}</span
                >
              </div>
            </div>
            <span class="product-block__date">{{
              $i18n.locale === "ru"
                ? `до ${product.date} `
                : `${product.date} gacha`
            }}</span>
          </div>
          <span class="product-block__content pdf">
            <span class="product-block__left">
              <span class="product-block__img">
                <img :src="product.image" alt="image" />
              </span>
            </span>
            <span class="product-block__right">
              <span class="product-block__descr">{{ $t(product.name) }} </span>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MagnumPromo",
  data() {
    return {
      products: [
        {
          name: "product-1",
          discount: "-30%",
          oldPrice: "255",
          newPrice: "179",
          date: "13.12",
          image: require("@/assets/img/magnum-promo/promo-1.png"),
        },
        {
          name: "product-2",
          discount: "-21%",
          oldPrice: "705",
          newPrice: "555",
          date: "13.12",
          image: require("@/assets/img/magnum-promo/promo-2.png"),
        },
        {
          name: "product-3",
          discount: "-34%",
          oldPrice: "955",
          newPrice: "629",
          date: "13.12",
          image: require("@/assets/img/magnum-promo/promo-3.png"),
        },
        {
          name: "product-4",
          discount: "-30%",
          oldPrice: "2149",
          newPrice: "1499",
          date: "13.12",
          image: require("@/assets/img/magnum-promo/promo-4.png"),
        },
        {
          name: "product-5",
          discount: "-31%",
          oldPrice: "725",
          newPrice: "499",
          date: "13.12",
          image: require("@/assets/img/magnum-promo/promo-5.png"),
        },
        {
          name: "product-6",
          discount: "-43%",
          oldPrice: "695",
          newPrice: "399",
          date: "13.12",
          image: require("@/assets/img/magnum-promo/promo-6.png"),
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.promo__inner {
  .product-block {
    padding: 15px 12px 25px 15px;
  }
}
.product-pdf {
  &__header {
    display: flex;
    justify-content: space-between;
    .product-block__date {
      margin-top: 10px;
      font-size: 20px;
      line-height: 87.69%;
    }
  }
  &__price {
    display: inline-block;
    width: 128px;

    &-wrapper {
      background-color: #fce300;
      padding: 4px;
      &.best-price {
        span {
          color: #f50f64;
        }
        .product-pdf__price-old {
          font-size: 14px;
          line-height: 18px;
          text-decoration: none;
        }
      }
    }
    span {
      color: #2d2f32;
      font-family: "GT Walsheim Pro Bold";
      text-align: right;
    }
    &-old {
      font-size: 18px;
      line-height: 18px;
      text-decoration-line: line-through;
    }
    &-new {
      font-size: 22px;
      line-height: 18px;
    }
  }
  &__stock {
    font-family: "GT Walsheim Pro Bold";
    background-color: #f50f64;
    color: #ffff;
    font-size: 26px;
    line-height: 26px;
    padding: 12px;
    text-align: center;
    img {
      width: 45px;
      height: 18px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 900px) {
  .product-pdf__stock {
    font-size: 22px;
    line-height: 24px;
  }
  .product-pdf__price {
    width: 120px;
  }
  .product-pdf__price-wrapper.best-price .product-pdf__price-old {
    font-size: 12px;
    line-height: 12px;
  }
  .product-pdf__price-new {
    font-size: 20px;
  }
  .product-pdf__price-old {
    font-size: 14px;
    line-height: 14px;
  }
  .product-pdf__header .product-block__date {
    font-size: 18px;
  }
}
</style>
